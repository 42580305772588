
/* Nunito */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Nunito-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Nunito-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Nunito-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Nunito-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Nunito-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Nunito-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Nunito-BoldItalic.ttf') format('truetype');
}


/* Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: thin;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Roboto-Thin.woff2') format('woff2'), url('../fonts/Roboto-Thin.woff') format('woff');}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Roboto-Italic.woff2') format('woff2'), url('../fonts/Roboto-Italic.woff') format('woff');}
  
  @font-face {
  font-family: 'Roboto';
  font-style: medium;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Roboto-Medium.woff2') format('woff2'), url('../fonts/Roboto-Medium.woff') format('woff');}
  
  @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Roboto-Regular.woff2') format('woff2'), url('../fonts/Roboto-Regular.woff') format('woff');}

@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Roboto-Bold.woff2') format('woff2'), url('../fonts/Roboto-Bold.woff') format('woff');}
