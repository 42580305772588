/* module-key = 'confluence.web.resources:view-comment', location = '/includes/css/comments.css' */

ol.comment-threads {
    list-style-type: none;
    margin: 0;
    padding-left: 40px; /* primary indent for comments */
}
ol.comment-threads.top-level {
    /* accounts for some margins on the section header */
    margin-top: 5px;
    margin-bottom: 0;
    padding-left: 0;
    border-bottom: 1px solid #ccc;
}

#comments-section {
    margin-top: 11px; /* Overrides the default margin of .pageSection */
}

#comments-section .comment {
    border-top: 1px solid #ccc;
    clear: left;
    padding: 10px 0 10px 0;
    margin: 0 0 -1px 0;
    position: relative;
}

#comments-section td.comment {
    position: static;
}

#comments-section .comment.add { /* targets only a fresh comment as opposed to a reply. */
    border-top: 0;
}

#comments-section .comment.focused + ol.comment-threads {
    border-top: 1px solid #ccc;
}

#comments-section .comment.focused + ol.comment-threads > li.comment-thread:first-child > .comment {
    border-top: 0;
}

/* Will want to resize this server side when temporary PDL is finished */
.comment-user-logo img {
    width: 32px;
    height: 32px;
    border-radius: 2px;
}

.comment-header .author {
    line-height: 1.2;
    color: #666;
    padding: 0;
}

.comment-header .author a {
    vertical-align: top;
}

.comment-header .author .author-lozenge {
    font-size: 10px;
    margin: 0 8px;
    padding: 2px 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border-width: 1px;
    vertical-align: middle;
}

.comment .date {
    display: inline-block;
    margin-left: -2px;
}

.comment .date::before {
    content: '\22C5'; /* middot */
    color: #333;
}

.comment .date a {
    color: #707070;
}

.comment-content {
    margin-top: -5px; /* we could also write a ie7 specific javascript and use first child on the style below avoids negative margins */
}

.global-comment-actions li,
.comment-actions li {
    border: 0;
    list-style-type: none;
    display: inline-block;
    margin: 0; /* for IE */
    color: #707070;
}

.comment-actions-primary {
    overflow: auto; /* since this container contains floated elements. See .comment-actions-primary li */
}

.comment-actions-primary li {
    float: left; /* use float instead of inline-block, as the latter causes unwanted spacing if there are any newline characters in the markup */
}

.comment-actions {
    margin-top: 10px;
    font-size: 12px;
    clear: both;
}

.comment-actions:after {
    content: "";
    display: table;
    clear: both;
}

.comment-actions li a {
    font-size: 12px;
}

.comment-actions li:first-child:before { /* left-most comment option -- no left middot */
    display: none;
}

li#comment-show-hide {
    padding-left: 0;
}

.comment-actions li:before,
.comment-action-separator {
    content: '•'; /* middot */
    color: #707070;
    padding: 0 5px;
}

/* There is no link icon in the PDL. It may end up being the fuzzy time */
.comment-actions .comment-actions-secondary {
    display: none;
}

.global-comment-actions li.hidden,
.comment-actions li.hidden {
    display: none;
}

.global-comment-actions a {
    margin: 0;
    text-decoration: none;
}

.global-comment-actions,
.comment-actions ul {
    display: inline;
    padding: 0;
    margin: 0; /* for IE */
}

.comment-body #editor-container {
    margin: 0 20px 0 0;
    min-height: 250px;
}

#comments-section .comment.focused {
    border-bottom: 1px solid #ccc;
    background: #ebf2f9;
    margin-left: -10px;
    padding-left: 10px;
}

.comment .comment-user-logo {
    width: 32px;
    margin: 0 10px 0 0;
    padding: 0;
    float: left;
}

.comment .comment-user-logo .replacement {
    height: 48px;
    width: 48px;
}
.comment.collapsed .comment-user-logo img,
.comment.collapsed .comment-user-logo .replacement {
    height: 24px;
    width: 24px;
    margin-left: 12px;
}

.comment.collapsed .comment-body {
    opacity: 0;
    height: 0;
}

img.wait {
    vertical-align: middle;
}
.comment .excerpt {
    color: #666;
    font-weight: normal;
    opacity: 0;
    height: 1.8em;
}

.collapsed .excerpt {
    opacity: inherit;
}

.comment form .label {
    font-weight: normal;
    color: #666;
}

.comment input#watchPageAfterComment {
    vertical-align: middle;
    margin-left: 20px;
}

#comments-section .comment-actions a {
    color: #707070;
}

.comment-body,
.comment-header {
    padding: 0 0 0 42px;
}

.comment-body .comment-content.wiki-content { /* Apply left padding in body to accommodate macros CONFDEV-13918 */
    padding-right: 20px;
}

.comment-header {
    margin-bottom: 10px;
}

#comments-section #preview {
    border-top: 1px solid #bbb;
}

#comments-section #previewArea iframe {
    height: 210px; /* Default height of the editor in comments */
}

#comments-section .section-header {
    position: relative;
}

#comments-section .comment-body form {
    display: table;
    padding: 0;
    width: 100%;
    border-top: 0;
}

#comments-section .comment-body form .editor-container {
    border: 1px solid #bbb;
    border-top: none;
}

.comment-body #rte {
    overflow-x: hidden;
    padding: 8px;
}

#savebar-container {
    border-bottom: 1px solid #ccc;
}
#savebar-container .aui-toolbar {
    border-bottom: 0;
}

/* Style the on-page gallery */

.gallery {
    margin: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}

.gallery table {
    border-collapse: collapse;
}

.gallery th, .gallery td {
    text-align: center;
}

.gallery td {
    padding: 0; /* don't pad empty cells */
    vertical-align: top;
}

.gallery .gallery-image {
    line-height: 0; /* overrides Confluence style, needed to avoid extra space below images */    
}

.gallery .gallery-image a.gallery-link {
    display: block;
}

.gallery .gallery-image img {
    margin: 5px;
}

.gallery .gallery-caption {
    text-align: center;
}

/* module-key = 'confluence.web.resources:panel-styles', location = '/includes/css/panels.css' */
.panel,
.alertPanel,
.infoPanel {
    color: #333;
    padding: 0;
    margin: 10px 0;
    border: 1px solid #ddd;
    overflow: hidden;
    border-radius: 3px;
}

.alertPanel, .infoPanel, .panelContent {
    padding: 10px;
}

.alertPanel {
    border-color: #c00;
}

.infoPanel {
    border-color: #69c;
}

.panelHeader {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #f7f7f7;
}

/* basic panel (basicpanel.vmd) style */
.basicPanelContainer {
    border-width: 1px;
    border-style: solid;
    margin-top: 2px;
    margin-bottom: 8px;
    width: 100%;
}

.basicPanelContainer:first-child {
    margin-top: 0;
}

.basicPanelTitle {
    padding: 10px;
    margin: 0;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
}

.basicPanelBody {
    padding: 5px;
    margin: 0;
}

/* Remove this style when AJS-554 is resolved. The ID may also be removed from markup upon resolution. */
#comments-actions.aui-toolbar {
    background-color: transparent;
    border-top: 0;
    padding-top: 12px;
}

/* Text editor */
#addcomment.comment-text .userLogo {
    opacity: 0.5;
}

#addcomment.comment-text.active .userLogo {
    opacity: 1;
}

#comments-text-editor textarea {
    -moz-border-radius: .3125em;
    -webkit-border-radius: .3125em;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    height: 1.5em;
    padding: 5px 0;
    text-indent: 5px;
}

#addcomment.active #comments-text-editor textarea {
    -moz-border-radius: .3125em;
    -webkit-border-radius: .3125em;
    border: 1px solid #bbb;
    height: 100px;
    outline-width: 0px;
    padding: 0;
    text-indent: 0;
}

#comments-text-editor .text-editor-chrome {
    display: none;
}

#addcomment.active #comments-text-editor .text-editor-chrome {
    display: block;
}

#comments-text-editor .comment-actions {
    margin: -24px 0 0;
    padding: 0;
    float: right;
}

#comments-text-editor .toolbar-group {
    float: right;
    margin: 0;
}

#comments-text-editor .aui-toolbar {
    background-color: transparent;
    border-top: none;
}

.comment-actions-primary {
    line-height: 16px; /* by default the inherited line-height is 1.3 which computes to 15px which makes it impossible to vertically align the text inside this container */
}

/* module-key = 'com.atlassian.confluence.plugins.quickreload:quick-reload', location = 'css/quick-reload.css' */
div.qr-notice {
	position: fixed;
	bottom: 0px;
	right: 20px;
	z-index: 9999;
    border-radius: 0px;
    padding: 5px;
    display: none;
}

/* module-key = 'confluence.extra.layout:resources', location = 'com/atlassian/confluence/extra/layout/css/styles.css' */
.sectionMacro {
    margin-top: 10px;
    display: table;
    width: 100%;
}

.sectionMacroRow {
    display: table-row;
}

.columnMacro {
    display: table-cell;
    vertical-align: top;
}

.sectionMacro .columnMacro {
    border: none;
    padding: 0;
}

.sectionMacro .columnMacro + .columnMacro {
    padding-left: 20px;
}

.sectionMacroWithBorder .columnMacro {
    border-style: dashed;
    border-width: 1px;
    border-color: #ccc;
    padding: 10px;
}
/* module-key = 'com.atlassian.confluence.plugins.status-macro:view_content_status', location = 'css/status-view.css' */
.status-macro {
	min-width: 76px;
	padding: 2px 5px 1px 5px
}
/* module-key = 'com.atlassian.confluence.plugins.confluence-create-content-plugin:create-from-template-resources', location = 'com/atlassian/confluence/plugins/createcontent/css/create-from-template-macro.css' */
#main-content a.create-from-template-button {
    color: #333333;
}
/* module-key = 'com.atlassian.confluence.plugins.confluence-content-report-plugin:resources', location = 'com/atlassian/confluence/plugins/content_report/css/content-report-table.css' */
.content-report-table-macro {
    table-layout: fixed;
}

table.aui.content-report-table-macro a {
    text-decoration: none;
}

table.aui.content-report-table-macro a:focus,
table.aui.content-report-table-macro a:hover,
table.aui.content-report-table-macro a:active {
    text-decoration: underline;
}

.content-report-table-macro th:first-child {
    width: 60%;
}

.content-report-table-macro td,
.content-report-table-macro th,
.content-report-table-macro th > div /* this is the table sorter div */ {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

table.aui.tablesorter.content-report-table-macro > thead > tr > th { /* override table sorter header style */
    background-color: #fff;
    background-repeat: no-repeat;
}

.more-results {
    margin: 15px 0px 0px 10px;
}

/* module-key = 'com.atlassian.confluence.plugins.confluence-content-report-plugin:resources', location = 'com/atlassian/confluence/plugins/content_report/css/aui-table-override.css' */
/*
 * Can't wait for latest PDL tables to land.
 * Feel free to remove this whole file once it does.
 */
table.aui.content-report-table-macro {
    border-collapse: collapse;
    width: 100%;
}

table.aui.content-report-table-macro table.aui.content-report-table-macro {
    margin: 0;
}
table.aui.content-report-table-macro > caption {
    color: #707070;
    background: #f5f5f5;
    border-bottom: 1px solid #ccc;
    caption-side: top;
    padding: 7px 10px;
    text-align: left;
}
table.aui.content-report-table-macro > tbody > tr,
table.aui.content-report-table-macro > tfoot > tr {
    background: #fff;
    border-bottom: 1px solid #ccc;
    color: #333;
}

table.aui.content-report-table-macro > thead > tr > th,
table.aui.content-report-table-macro > tbody > tr > th,
table.aui.content-report-table-macro > thead > tr > td,
table.aui.content-report-table-macro > tbody > tr > td,
table.aui.content-report-table-macro > tfoot > tr > td {
    padding: 7px 10px;
    text-align: left;
    vertical-align: top;
}
table.aui.content-report-table-macro > thead {
    border-bottom: 1px solid #ccc;
}
/* Borders on TBODY and TFOOT don't work in IE - whereas these at least work in IE7 & IE8 */
table.aui.content-report-table-macro > tbody > tr:first-child > td,
table.aui.content-report-table-macro > tbody > tr:first-child > th,
table.aui.content-report-table-macro > tfoot > tr:first-child > td {
    border-top: 1px solid #ccc;
}
table.aui.content-report-table-macro > thead > tr > th > ul.menu,
table.aui.content-report-table-macro > tbody > tr > th > ul.menu,
table.aui.content-report-table-macro > tbody > tr > td > ul.menu,
table.aui.content-report-table-macro > tfoot > tr > td > ul.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
table.aui.content-report-table-macro > thead > tr > th > ul.menu > li,
table.aui.content-report-table-macro > tbody > tr > th > ul.menu > li,
table.aui.content-report-table-macro > tbody > tr > td > ul.menu > li,
table.aui.content-report-table-macro > tfoot > tr > td > ul.menu > li {
    float: left;
    margin: 0 10px 0 0;
    width: auto;
}

/* module-key = 'com.atlassian.confluence.plugins.confluence-mentions-plugin:mentions-styling', location = 'css/mentions.css' */
.wiki-content .user-mention {
    display: inline-block;
    background: #f5f5f5;
    padding: 0 2px;
    border: 1px solid #ddd;
    border-radius: 3px;
    line-height: 16px;
    white-space: nowrap;
}

.wiki-content .user-mention:before,
.wiki-content .user-mention:hover:before,
.wiki-content .user-mention:active:before,
.wiki-content .user-mention:visited:before {
    color: #999; 
    content: "@";
    margin-right: 2px;
    font-size: 12px;
    vertical-align: top;
    line-height: 16px;
    text-decoration: none;
    /* this is necessary for the text-decoration to be applied in Chrome/FF */
    display: inline-block;
}
/* module-key = 'com.atlassian.confluence.plugins.drag-and-drop:support', location = 'css/drag-and-drop.css' */
#upload-statuses{list-style:none;margin:0;padding:0;}#upload-statuses li{padding:9px 20px 10px 20px;border-top:1px solid #cccccc;}#upload-statuses li:first-child{border-top:0;padding-top:10px;}
#upload-statuses li .aui-message{margin-top:0px;}
#upload-statuses li label{margin-top:-4px;display:block;}
#upload-statuses .file-upload-progress-text{color:#707070;font-size:12px;display:block;float:right;margin:0px 5px;}
#upload-statuses .cancel-or-success-placeholder{color:#707070;display:table-cell;padding:0 0 0 10px;vertical-align:middle;}#upload-statuses .cancel-or-success-placeholder .aui-iconfont-remove:hover{cursor:pointer;}
#upload-statuses .file-upload-progress-block{overflow:hidden;display:table;width:100%;vertical-align:middle;}
#upload-statuses .aui-progressbar{display:table-cell;width:100%;line-height:16px;vertical-align:middle;}
#upload-statuses .ui-widget-header{border:0;background:#cccccc url('/confluence/s/en_GB-1988229788/4390/NOCACHE1/5.2/_/download/resources/com.atlassian.confluence.plugins.drag-and-drop:support/../../resources/com.atlassian.confluence.plugins.drag-and-drop/images/ui-bg_highlight-soft_75_cccccc_1x100.png') 50% 50% repeat-x;}
#upload-statuses .ui-widget-content{border:1px solid #cccccc;background:#f5f5f5 url('/confluence/s/en_GB-1988229788/4390/NOCACHE1/5.2/_/download/resources/com.atlassian.confluence.plugins.drag-and-drop:support/../../resources/com.atlassian.confluence.plugins.drag-and-drop/images/ui-bg_flat_75_ffffff_40x100.png') 50% 50% repeat-x;}
#upload-statuses .ui-progressbar{height:18px;text-align:left;}#upload-statuses .ui-progressbar .ui-progressbar-value{margin:-1px 0px;height:100%;}
#upload-statuses .ui-widget{font-family:Arial,sans-serif;}
#upload-statuses .ui-corner-tl{-moz-border-radius-topleft:3px;-webkit-border-top-left-radius:3px;border-top-left-radius:3px;}
#upload-statuses .ui-corner-tr{-moz-border-radius-topright:3px;-webkit-border-top-right-radius:3px;border-top-right-radius:3px;}
#upload-statuses .ui-corner-bl{-moz-border-radius-bottomleft:3px;-webkit-border-bottom-left-radius:3px;border-bottom-left-radius:3px;}
#upload-statuses .ui-corner-br{-moz-border-radius-bottomright:3px;-webkit-border-bottom-right-radius:3px;border-bottom-right-radius:3px;}
#upload-statuses .ui-corner-top{-moz-border-radius-topleft:3px;-webkit-border-top-left-radius:3px;border-top-left-radius:3px;-moz-border-radius-topright:3px;-webkit-border-top-right-radius:3px;border-top-right-radius:3px;}
#upload-statuses .ui-corner-bottom{-moz-border-radius-bottomleft:3px;-webkit-border-bottom-left-radius:3px;border-bottom-left-radius:3px;-moz-border-radius-bottomright:3px;-webkit-border-bottom-right-radius:3px;border-bottom-right-radius:3px;}
#upload-statuses .ui-corner-right{-moz-border-radius-topright:3px;-webkit-border-top-right-radius:3px;border-top-right-radius:3px;-moz-border-radius-bottomright:3px;-webkit-border-bottom-right-radius:3px;border-bottom-right-radius:3px;}
#upload-statuses .ui-corner-left{-moz-border-radius-topleft:3px;-webkit-border-top-left-radius:3px;border-top-left-radius:3px;-moz-border-radius-bottomleft:3px;-webkit-border-bottom-left-radius:3px;border-bottom-left-radius:3px;}
#upload-statuses .ui-corner-all{-moz-border-radius:3px;-webkit-border-radius:3px;border-radius:3px;}
#drag-and-drop-disabled .panel-body,#drag-and-drop-message-dialog .panel-body{color:#666;font-size:10pt;}
#drag-and-drop-message-dialog .panel-body p,#drag-and-drop-disabled .panel-body p{color:#666;font-size:10pt;margin-top:1em;}
#drag-and-drop-disabled div{padding:5px;}
#drag-and-drop-cue{position:absolute;top:50%;left:50%;width:26em;height:1em;margin-top:-0.5em;margin-left:-13em;background-color:white;z-index:2900;padding:10px;-moz-border-radius:3px;-webkit-border-radius:3px;}
#drag-and-drop-progress-dialog .panel-body{padding:0;}

/* module-key = 'com.atlassian.confluence.plugins.drag-and-drop:support', location = 'css/drop-zone.css' */
.attachments-drop-zone{border-radius:10px;-moz-border-radius:10px;-webkit-border-radius:10px;border:3px dashed #e9e9e9;color:#707070;font-size:18px;height:124px;padding:25px;text-align:center;width:420px;}.attachments-drop-zone .drop-zone-image{background-image:url('/confluence/s/en_GB-1988229788/4390/NOCACHE1/5.2/_/download/resources/com.atlassian.confluence.plugins.drag-and-drop:support/../../resources/com.atlassian.confluence.plugins.drag-and-drop/images/arrow.gif');width:55px;height:55px;margin:10px auto;}
.attachments-drop-zone .drop-zone-text{margin:0 auto;padding-top:6px;width:240px;}
.drop-zone-on-hover{background-color:#f5f5f5;}
#attached-images .attached-image .drop-zone-on-hover{background-color:#f5f5f5;}
#attached-images .attached-image .image-dialog-drop-zone-container{border-radius:10px;-moz-border-radius:10px;-webkit-border-radius:10px;border:3px dashed #e9e9e9;line-height:1em;}#attached-images .attached-image .image-dialog-drop-zone-container .drop-zone-image{background-image:url('/confluence/s/en_GB-1988229788/4390/NOCACHE1/5.2/_/download/resources/com.atlassian.confluence.plugins.drag-and-drop:support/../../resources/com.atlassian.confluence.plugins.drag-and-drop/images/arrow.gif');width:55px;height:55px;margin:13px auto 0;}
#attached-images .attached-image .image-dialog-drop-zone-container .drop-zone-text{font-weight:bold;color:#bbb;margin-top:5px;}

/* module-key = 'com.atlassian.confluence.plugins.confluence-like:content-like-resources', location = 'css/like.css' */
#likes-and-labels-container {
    overflow: hidden;
    margin: 10px 0;
    padding: 10px 0;
    font-size: 13px;
    clear: both; /* Make sure we clear previous page content floating element */
}

#likes-and-labels-container > div {
    width: 48%;
}

#likes-section {
    float: left;
}

#labels-section {
    float: right;
}

#likes-section .like-button {
    display: block;
    float: left;
    padding: 3px 0 0 23px;
    margin-top: -3px;
}

#likes-section .like-summary {
    padding-left: 20px;
}

#likes-dialog-body > ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

#likes-dialog-body > ol > li {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    overflow: auto;
}

#likes-dialog-body > ol > li:last-child {
    border-bottom: none;
}

#likes-dialog-body > ol > li > .avatar-container,
#likes-dialog-body > ol > li > .like-user,
#likes-dialog-body > ol > li > .follow-button-container {
    vertical-align: middle;
    display: table-cell;
}

#likes-dialog-body > ol > li > .like-user {
    width: auto;
    padding: 0 10px;
}

#likes-dialog-body > ol > li > .avatar-container,
#likes-dialog-body > ol > li > .follow-button-container {
    width: 1%;
}

#likes-dialog-body > ol > li > .avatar-container > a {
    height: 32px;
    display: block;
}
#likes-dialog-body li .like-user-avatar {
    border-radius: 3px;
    height: 32px;
}

.comment-actions .small-like-icon {
    width: 16px;
    height: 14px;
    display: inline-block;
    margin: 0 3px 0 0;
    vertical-align: text-bottom;
    background-position: 0px -1px; /* -1px corrects the vertical alignment to make the icon's base flush with text base */
}

/* AUI overrides */
#likes-dialog-body > ol > li > .aui-toolbar {
    padding-top: 0;
}
#likes-dialog-body > ol > li > .aui-toolbar > .toolbar-group {
    margin: 0;
}

/* module-key = 'com.atlassian.confluence.plugins.view-storage-format:view-comment-storage-resources', location = 'com/atlassian/confluence/plugins/viewstorage/css/viewcommentstorage.css' */
.comment-actions .action-view-comment-storage {
    padding:0;
}

.comment-actions .action-view-comment-storage a:hover {
    visibility: visible; /* for IE */
}
.comment-actions .action-view-comment-storage a {
    outline: none;
}

.comment-actions .action-view-comment-storage a,
.comment-actions .action-view-comment-storage a span /* span for IE */ {
    background: url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/5.2/_/download/resources/com.atlassian.confluence.plugins.view-storage-format:view-comment-storage-resources/../../../images/icons/document_zoom_in_16.png) no-repeat 50% 50%;
    width: 16px;
    height: 16px;
    display: block;
    text-indent: -9999px;
    cursor: pointer;
    margin-top: 1px;
}
/* module-key = 'com.atlassian.confluence.plugins.confluence-page-layout:pagelayout-content-styles', location = 'css/pagelayout/page-layout-content.css' */
/* Has to be at high specificity so as to beat first-child differences from
   .view .wiki-content .cell:first-child and .wiki-content .cell*/
.wiki-content .contentLayout2 .columnLayout .cell  {
    padding-left: 15px;
    padding-right: 15px;
}

/** This can't be bigger than the gutter or we get a scrollbar. */
.wiki-content .contentLayout2{
    margin: 0 -15px;
}

/* module-key = 'com.atlassian.confluence.plugins.confluence-sortable-tables:sortable-table-resources', location = 'css/tables.css' */
/**
 * Sortable Tables
 */

table.confluenceTable th.confluenceTh > .tablesorter-header-inner > p {
    font-weight: bold;
    margin-bottom: 0;
}

table.confluenceTable td.confluenceTd > .tablesorter-header-inner > :first-child,
table.confluenceTable th.confluenceTh > .tablesorter-header-inner > :first-child {
    /* CONF-26496 - Should be zero, but this causes the border to be rendered 2px high in some cases in Chrome */
    margin-top: 0.2px;
}

.wiki-content table.tablesorter > thead > tr > th {
    background: #F0F0F0 center right no-repeat;
    padding-right: 15px;
    cursor: pointer;
}

.wiki-content table.tablesorter > thead > tr:hover > th {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKBAMAAACQ3rmwAAAAA3NCSVQICAjb4U/gAAAAD1BMVEX///9QYXNQYXNQYXNQYXNa92HSAAAABXRSTlMAESIzRJTdRHwAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAedEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzUuMasfSOsAAAB5dEVYdG5hbWVTZXRzAHsgZGVzY3JpcHRpb246IkRvd25sb2FkIHRoZSBcIk5hbWVkIFNlbGVjdGlvbiBTZXRzXCIgcGFuZWwgZnJvbSBodHRwOi8vZmlyZXdvcmtzLmFiZWFsbC5jb20iLCBpZDotMSwgc2V0czp7ICB9IH1YpK+bAAAAFXRFWHRDcmVhdGlvbiBUaW1lADI5LzMvMTJcby01AAAAJUlEQVQImWNgUGIAAUYXARDF4uIIokxcnBkwAFRQxMUBSQNEOwB/EQOGsCyAPwAAAABJRU5ErkJggg==);
}

.wiki-content table.tablesorter > thead > tr > th.tablesorter-headerSortDown {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKBAMAAACQ3rmwAAAAA3NCSVQICAjb4U/gAAAAHlBMVEX///9QYXNQYXNQYXNQYXNQYXNQYXNQYXNQYXNQYXOMgbQDAAAACnRSTlMAESIzRGZ3maq7ZAl7FAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAB50RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNS4xqx9I6wAAAHl0RVh0bmFtZVNldHMAeyBkZXNjcmlwdGlvbjoiRG93bmxvYWQgdGhlIFwiTmFtZWQgU2VsZWN0aW9uIFNldHNcIiBwYW5lbCBmcm9tIGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSIsIGlkOi0xLCBzZXRzOnsgIH0gfVikr5sAAAAVdEVYdENyZWF0aW9uIFRpbWUAMjkvMy8xMlxvLTUAAAAdSURBVAiZY2AIYwAB5pkOIEpiZhOIqpw5nYEUAADZjgQPuazuBAAAAABJRU5ErkJggg==);
}

.wiki-content table.tablesorter > thead > tr > th.tablesorter-headerSortUp {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKBAMAAACQ3rmwAAAAA3NCSVQICAjb4U/gAAAAHlBMVEX///9QYXNQYXNQYXNQYXNQYXNQYXNQYXNQYXNQYXOMgbQDAAAACnRSTlMAESIzRGZ3maq7ZAl7FAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAB50RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNS4xqx9I6wAAAHl0RVh0bmFtZVNldHMAeyBkZXNjcmlwdGlvbjoiRG93bmxvYWQgdGhlIFwiTmFtZWQgU2VsZWN0aW9uIFNldHNcIiBwYW5lbCBmcm9tIGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSIsIGlkOi0xLCBzZXRzOnsgIH0gfVikr5sAAAAVdEVYdENyZWF0aW9uIFRpbWUAMjkvMy8xMlxvLTUAAAAdSURBVAiZY2AgGlTOnA6iNGY2giiWmQZg0VQQAQBOSAQedpgI7AAAAABJRU5ErkJggg==);
}

/* module-key = 'com.atlassian.confluence.plugins.pagetree:pagetree-css-resources', location = 'com/atlassian/confluence/plugins/pagetree/resource/css/pagetree.css' */
#pagetreesearch form input.text {
    margin: 0 8px 8px 0;
}

#pagetreesearch form input.aui-button {
    margin: 0;
}

#splitter-sidebar #pagetreesearch form {
    white-space: nowrap;
}

div.plugin_pagetree div.error {
    padding-left: 15px;
}

div.plugin_pagetree ul.plugin_pagetree_children_list_noleftspace {
    margin-left: 0;
    padding-left: 0;
}

div.plugin_pagetree ul.plugin_pagetree_children_list {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
}

div.plugin_pagetree ul.plugin_pagetree_children_list li ul.plugin_pagetree_children_list {
    padding-left: 16px;
}

div.plugin_pagetree ul.plugin_pagetree_children_list li, div.plugin_pagetree ul.plugin_pagetree_children_list li div.plugin_pagetree_children_container {
    clear: left;
    overflow: hidden;
    width: 100%;
}

div.plugin_pagetree ul.plugin_pagetree_children_list li .plugin_pagetree_children_content {
    float: left;
    width: 85%;
    text-decoration: none;
}

div.plugin_pagetree ul.plugin_pagetree_children_list li .plugin_pagetree_children_content .plugin_pagetree_children_span.plugin_pagetree_current {
    font-weight: bold;
}

div.plugin_pagetree a:link {
    text-decoration: none;
}

div.plugin_pagetree a:hover {
    text-decoration: underline;
}

div.plugin_pagetree ul.plugin_pagetree_children_list li .plugin_pagetree_childtoggle_container {
    float: left;
    width: 15px;
}

div.plugin_pagetree .plugin_pagetree_childtoggle_container {
    padding-bottom: 2px;
}

div.plugin_pagetree ul.plugin_pagetree_children_list li {
    padding: 2px 0;
}

div.plugin_pagetree ul.plugin_pagetree_children_list li:last-child {
    padding: 2px 0 0 0;
}

.plugin_pagetree_childtoggle_container .icon-minus {
    background: transparent url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.3.0-m2/_/download/resources/com.atlassian.confluence.plugins.pagetree:pagetree-css-resources/../../../images/icons/tree_minus.gif) no-repeat scroll 0 4px;
}

.plugin_pagetree_childtoggle_container .icon-plus {
    background: transparent url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.3.0-m2/_/download/resources/com.atlassian.confluence.plugins.pagetree:pagetree-css-resources/../../../images/icons/tree_plus.gif) no-repeat scroll 0 4px;
}

.plugin_pagetree_childtoggle_container .icon-square {
    background: transparent url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.3.0-m2/_/download/resources/com.atlassian.confluence.plugins.pagetree:pagetree-css-resources/../../../images/icons/tree_square.gif) no-repeat scroll 0 4px;
}
/* module-key = 'com.atlassian.confluence.plugins.pagetree:pagetree-css-resources', location = 'com/atlassian/confluence/plugins/pagetree/resource/css/pagetree-adg.css' */
#pagetreesearch form input.text {
    margin:0 8px 8px 0;
}
#pagetreesearch form input.aui-button {
    margin:0;
}
#splitter-sidebar #pagetreesearch form {
    white-space:nowrap;
}
.plugin_pagetree div.error {
    padding-left:15px;
}
.plugin_pagetree .plugin_pagetree_children_list_noleftspace {
    margin-left:0;
    padding-left:0;
}
.plugin_pagetree .plugin_pagetree_children_list {
    list-style-type:none;
    margin-bottom:0;
    padding-left:0;
}
.plugin_pagetree .plugin_pagetree_children_list > li {
    margin-bottom: 5px;
}

.plugin_pagetree .plugin_pagetree_children_list > li .plugin_pagetree_children_list {
    padding-left:16px;
}
.plugin_pagetree .plugin_pagetree_children_list > li > .plugin_pagetree_children_content {
    float:left;
}

/* Increased specificity over pagetree.css to override width and padding-bottom*/
div.plugin_pagetree ul.plugin_pagetree_children_list > li > div.plugin_pagetree_childtoggle_container {
    position: relative;
    width:20px;
    height: 25px;
    float:left;
    padding-bottom: 0;
}
.plugin_pagetree_childtoggle_container > .icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    opacity: 1;
}
.plugin_pagetree_childtoggle_container > .no-children.icon {
    height: 4px;
    width: 4px !important;
    left: 6px;
    margin-top: -5px;
    background: #999;
    min-width: 0;
    border-radius: 2px;
}
.plugin_pagetree_children_span {
    display: block;
}
.plugin_pagetree_children_span > a {
    padding: 0;
    display: inline-block;
}
.plugin_pagetree_children_span.plugin_pagetree_current {
    font-weight: bold;
}

/* Increase specificity to remove padding with ADG */
div.plugin_pagetree ul.plugin_pagetree_children_list div.plugin_pagetree_children li {
    padding: 0;
    margin-bottom: 5px;
}
/* module-key = 'com.k15t.scroll.scroll-platform:getstarted-webresources', location = '/com/k15t/scroll/platform/ui/getstarted/getstarted.css' */
.acs-nav-item.sv-getstarted .icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAt+wAALfsB/IdK5wAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAKcSURBVDiNdZHNa5RnFMV/93nu8zzzzkwmjR/gF4nGFhu1BLuoFDRQtYIRXSRk41rsrpTu+j903103LtxIC22FVsX6gfWDUqkmDoohaJLJ55hJJsWZzLzv62KSgjCe3bmXcw73XKk3Vj9uNqYui3AAwIjlxcI47fDHygky0gAgThntL7gheb106amI7iPFADhruT3xrK3BhZlvcKa2QZNqnBa1unK9DxLS9WnGGh6XJtoaTJW+AlkBBIwYhAMyOPRlqupw3uOcQ50jFSU2iqpFxBDHTVbrioZNFAp5jDHUanUQQZcqiveeEEV4n8F7hw8BbwMqypt6ne6dOzl+5DMO7tvLls2buHL9Jr9du0kuG6FqBecM1goiMSGTJQSP8456o8mxgc85f27knVPmK1WijgI+hFZxSZKwY9s2DvV/glXHWjNGjGXXju2cPzfCcnWVydIsSZIQxwmluUWy2RzOeRSBtWaTvXt6+O7br1lYLPPL1Rv8efchxwf6+Ge0yNxCmaOHP8UYw9jzcSrV/+joyCOAguCdZ3J6BoCtWzYzcnaQSnWVB4+e0NvTzakvjrT+lqT8fusvoijCiAEBFcA55eXUFP+OPqX/4H6mZ2Z5NT3L8OmT5HNZSvOL1Gp1rt65z1x5iWwmAmn1IQMnT6fZfJ5sLk/nB10MnRnko97dqCqzC2Uu/nSFzkIHKYI1luDd/2IAs0FUlWYcc+nnX/n+hx/pKhS4dvseXZ0FgvdEIRDCu+KWASAiiAiqSi6X48M9PYy/mqRcWUZVYX3fDgYEEYMYgzGWJEno7d5FeWkZVdearxfW1kCEURESEUGMYK2l3mhSml8kBN9Kbi9OgDHTWGsMp2laTJMUUsiEwN+Pxyi+mCB4j7wvGorA8FtNNtn6vBW3dgAAAABJRU5ErkJggg==);
}

.sv-about {
    background-color: #F5F5F5;
    width: 770px;
    margin-top: 22px;
}

.aui-page-panel > .sv-about {
    margin: 0 auto;
}

.sv-about .sv-content {
    padding: 20px;
    color: #4A545D;
}

.sv-about .sv-content h1 {
    margin: 0 0 20px 0;
    text-indent: -9999px;
    background-image: url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.2.1-SNAPSHOT/_/download/resources/com.k15t.scroll.scroll-platform:getstarted-webresources/images/aboutpage_vsn-logo_lo.png);
    background-repeat: no-repeat;
    background-size: 154px 35px;
    height: 35px;
    width: 154px;
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
    .sv-about .sv-content h1 {
        background: url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.2.1-SNAPSHOT/_/download/resources/com.k15t.scroll.scroll-platform:getstarted-webresources/images/aboutpage_vsn-logo_hi.png) no-repeat;
        background-size: 154px 35px;
    }
}

.sv-about .sv-content .main > h2 {
    font-size: 21px;
    padding-left: 36px;
    color: #4A545D;
    margin: 30px 0 18px 0;
    background: no-repeat 0 4px transparent;
}

.sv-about .sv-content .main > h2.sv-features {
    background-image: url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.2.1-SNAPSHOT/_/download/resources/com.k15t.scroll.scroll-platform:getstarted-webresources/images/about_icon_features.png);
}

.sv-about .sv-content .main > h2.sv-configure {
    background-image: url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.2.1-SNAPSHOT/_/download/resources/com.k15t.scroll.scroll-platform:getstarted-webresources/images/about_icon_configure.png);
}

.sv-about .sv-content .main > h2.sv-share {
    background-image: url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.2.1-SNAPSHOT/_/download/resources/com.k15t.scroll.scroll-platform:getstarted-webresources/images/about_icon_share.png);
}

.sv-about .sv-content p,
.sv-about .sv-content ul li,
.sv-about .sv-content ol li {
    font-size: 14px;
}

.sv-about .sv-content ol {
    padding-left: 20px;
}

.sv-about .sv-content ul {
    padding-left: 16px;
}

.sv-about .sv-content ol li p {
    font-weight: normal;
    margin: 0 0 36px 0;
    padding-top: 0;
    color: #4A545D;
}

.sv-about .sv-content ol li.expando.expanded,
.sv-about .sv-content ol li.expando.expanded a {
    font-weight: bold;
    color: #4A545D;
}

.sv-about .sv-content ol li.expando p {
    display: none;
}

.sv-about .sv-content .sv-new {
    font-size: 11px;
    background-color: #88C494;
    color: white;
    padding: 0 2px;
}

.sv-about .sv-content a {
    color: #3972AC;
}

.sv-about .sv-content hr {
    border: 1px solid #CCC;
}

.sv-about .sv-content form.aui {
    padding: 0.5em 1em;
    text-align: center;
}

.sv-about .sv-content form.aui a.aui-button.aui-button-primary {
    color: white;
}

.sv-about .sv-content .sidebar {
    position: absolute;
    left: 495px;
    background-color: white;
    border-radius: 4px;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    top: 0;
}

.sv-about .sv-content .sidebar h2 {
    color: #88C494;
    font-size: 18px;
    margin-top: 28px;
}

.sv-about .sv-about-footer {
    background: url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/2.2.1-SNAPSHOT/_/download/resources/com.k15t.scroll.scroll-platform:getstarted-webresources/images/aboutpage_footer.png) 484px 9px no-repeat #4A555D;
    height: 70px;
    border-bottom: 10px solid #8B9196;
}

.sv-about .sv-about-footer p {
    margin: 0 20px;
    padding-top: 20px;
    font-size: 24px;
    color: #FFF;
}

/* module-key = 'com.k15t.scroll.scroll-platform:getstarted-webresources', location = '/com/k15t/scroll/platform/ui/getstarted/getstarted-conf4.css' */
/* make it look good on #CONF4 */

body:not(.aui-layout) .sv-about {
    margin: 0 auto;
}

body:not(.aui-layout) .sv-about .sv-content form.aui a.aui-button.aui-button-primary {
    color: #333;
}

/* module-key = 'com.atlassian.confluence.plugins.confluence-inline-tasks:inline-tasks-styles', location = 'css/inline-tasks.css' */
/***************
 * RTE Toolbar *
 ***************/

.shortcutsmenu ul.inline-task-list > li {
    padding-left: 22px;
    background-position: 0 3px; /* to match padding applied by #autoformat-shortcuts-panel .mod-item .item-description li */
    margin-left: -17px; /* to match left alignment in keyboard shortcuts (autoformat) dialog */
}

/************************************
 * Styling of inline task list item *
 ************************************/

.wiki-content ul.inline-task-list > li {
    list-style: none;
    padding-left: 22px;
    margin-left: -22px; /* positions checkbox background image before text */
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 0 3px;
}

.wiki-content td > ul.inline-task-list > li,
.wiki-content th > ul.inline-task-list > li {
    padding-left: 22px;
    margin-left: -17px; /* leave gap before checkbox background image in tables */
}

ul.inline-task-list img {
    vertical-align: top;
}

ul.inline-task-list .jira-issue, ul.inline-task-list .unknown-jira-issue {
    margin-top: -3px;
    margin-bottom: 3px;
}

ul.inline-task-list .jira-issue, ul.inline-task-list .unknown-jira-issue {
    margin-top: -3px;
    margin-bottom: 3px;
}



/***************************************
 * Incomplete vs complete inline tasks *
 ***************************************/

.wiki-content ul.inline-task-list > li.checked,
.wiki-content ul.inline-task-list > li.checked > *,
.wiki-content ul.inline-task-list > li.checked > * > a {
    color: #707070;
}

.wiki-content ul.inline-task-list > li.checked > ul.inline-task-list {
    color: #333;
}

/**
 * Only attempt for one level of nesting, otherwise we risk overriding a coloured span inside an unchecked inline task.
 * This will miss some edge cases but we don't have a better option until the markup changes
 */
.wiki-content ul.inline-task-list > li.checked > span[style],
.wiki-content ul.inline-task-list > li.checked > * > span[style] {
    color: #707070 !important; /* overrides inline styles */
}

/***************************************
 *              Notice                 *
 ***************************************/
#inline-tasks-notice {
    position: fixed;
    display: block;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 30%;
    margin: 15px auto auto auto;
    z-index: 999;
    padding: 5px;
    text-align: center;
}

/* module-key = 'com.atlassian.confluence.plugins.confluence-inline-tasks:inline-tasks-styles', location = 'css/checkboxes.css' */
/**********************************************************************************************************
 * NOTE: Pseudo-selectors for the different checkbox states haven't been used due to issues with nesting. *
 *       Classes are added and removed via javascript in inline-tasks.js, applicable to view mode only.   *
 **********************************************************************************************************/

ul.inline-task-list > li {
    /* data URI for images/icons/inline-tasks/unchecked.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA8ElEQVQ4Ec1SOwqEQAyNn85GRS3Eyis4eBTvIZ5DD+JBrKy1205BkLH3xxphZOOKsJU7MITkvbxkJgF4+kiiAc/z/CzLymVZDBG7soqiDHEcs6ZpXogfAnmec03TDMYYmKYJqqqS/HmegXMORVHANE1DFEUmEmTBwsphGILjOF/JyEFBxIIggM8uSRld1wEr3R3btglMBNZ1JeCVI8tH0zv8s8BZ9M8ExnGEbc7nLom/TYD45Al934NlWYRwdrquI6HjSyVJGqqqAhTBaVxdTK7rGpArVI5NdF3XT9O03IDbVd7wIUkS1rbtvspC6Dn7BkLhZ0vqv9lXAAAAAElFTkSuQmCC');
}

ul.inline-task-list > li.hover {
    /* data URI for images/icons/inline-tasks/unchecked-hover.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABAElEQVQ4EcWSOwqDQBCGJ1GsbBIQQcUiZ8gVBMGz2ApewMLWk3gLwZwhICJqExsbX5hMYGUnEZMikIFlmNc3P+wA/Nt2TICmaSff9y9t2x5Ybs3LstwEQXAuy/KK9QUQRdFNkqSDbdvwgIEoimR+HEeoqgriOIZ5nhvXdY/YsGdduNlxHDBN820YexBoGAZYlgW8SrJGURTATVuG6ngjgGmaAN+WCYJAym8AUv0iIIBP8td4vwV0Xbf6A/zmYRj4EIiCoihA13XS8BrkeU5Syx08jqhJkgQQglvYj/A+yzJI0xSwl1GWS1RV9eR53qXv+81TxuEwDM91XT9PmYH+5+9n6G0tfAw1tQAAAABJRU5ErkJggg==');
}

ul.inline-task-list > li.task-active {
    /* data URI for images/icons/inline-tasks/unchecked-pressed.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA+klEQVQ4EcVSOwqDQBCd+MPCZgP+Wk+g6M0ECw9gIXgmwdacIWCj2EQLKz8QR1hxRTRFwIVhdufz3ht2AO4+DypA13UrCIJX3/eExo68JElNHMdeXddvzK8ASZJ8ZFkmtm0DIQR4nmf6x3GEtm0hz3OYpqnxff+JBRytQmbXdUHTNBBFETiOY2xmXnKO48BWpUAB0Kuqun0e3g3DYOIMADJfnX0NA7Cf+woM8wyAIDDPX/r/DICUV2MMw8AoW78Ro/NyAI5xZmVZHgMoitKkaQpVVS0qUMneiqKALMsAaynKuommaVphGL66rjtdZWyOosibiZZVpkD3+S9rKUbNxwuQjwAAAABJRU5ErkJggg==');
}

ul.inline-task-list > li.disabled {
    /* data URI for images/icons/inline-tasks/unchecked-disabled.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA5klEQVQ4EcVSMQ6DMAx0UUBIICS6A0rf0JGX8EBewtg3ZICBrUhIMABCjSMlxW2UDh3wYuw7O2dxAGfHRQvI8/xW1/XD9/1U92x5Xdehqqp727YCcbOgaZpnkiRpURQg89fstm0wzzMIIWCapqEsyyuSPM3Elznn1mHkMMYUJpXCUaVZgKQwDDE5I45jgjNSyQKlusLzyJvvE1xDLowo2PfdxbVify8gB/26HyV8cogC+X8hiiKrVN0cx1F/qmwUoMP6vkeTEMKxwGHkIFf3jROzLFNWDoLAaeVlWZSVu65TVtaLzssvLOpSQnS3q6kAAAAASUVORK5CYII=');
}

ul.inline-task-list > li.checked {
    /* data URI for images/icons/inline-tasks/checked.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB10lEQVQ4EcVSz0sbQRT+8sO2YQnJmk02uzY9eCplvfVf6J/gHyBIIVCwRkF7FKSoNPXQQ4tWjAU1oocIuUi1EUFPEcGTBzVVNHrQTWp6SExmpzuLM3Rp8eqDx3sz7/u+eW9mgAe0bsMwih7egN7R0fkxnS5SSmW+979Yq9UqqVRqudFodMuyvCsEstms+ejxE/n5CwORmAq/z+fitwjBydEhvk59Rj6fR71eXzdN85WXo4hFHXI0rsPr9cGicPnNTQ2ZuQxyuRw0TQMjM64QYAtZUWFZ5B8/Oz/H3OwMfmx8RyKRQDKZZHDHXAKEUjQJhSoHMfC2D+WLS5R+nmL+WwYT70chBQJYXStAkiTOh19kdmKP4Sx7el9jf28XszPToJaFna0CuroMfMksCAzniQ689nUSYjn+pn8IqqZje3MDK0uLDpaRI0rMqXMyi0KAHd6yT2MeDIcxMj6JSDQGXYtj4tMUQu2KqN816ui4RmjcNuG7ez4pGMK7kXH8qprQnj5Dy+6OGbGf829zCVSvrxBWoqKuqCqYE7srblXzmqdOFAJNikq5dCDD44EUYkFMJwi/K1ewMWBYvil+YlzTO8c+pIttHtz7lRl5eHDg5eVF+ZiLPGz8A3oPzeM2AZRyAAAAAElFTkSuQmCC');
}

ul.inline-task-list > li.checked.hover {
    /* data URI for images/icons/inline-tasks/checked-hover.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB50lEQVQ4EcVSS0gbURQ9k0Q7pAYdkyYTxw+4jjsXgiBIsbssXXZTSndiXWhEuii0UkPQKHalGIKWSHHVTTcNMa0bKRERoQiKRloHlDBpba3OJzPOG3mjo+LWC4fHu/ecw733PeAeozcSiRQY2gAfDrfGhkcK8ukJR3O3napmlONjo0uyLPdyHLdmGySnpiUvW811dD1GMBSGp8rj0Guqhp3tLbxPJvA1n4eiKFlJknpclHX2/x/X2f0EfEMjXG43dN1w4PjvMTIfFpDL5SAIAoiYaG0DcvHVByFr+g3s//yFdGoOy9kvaG5qQjQaJXQrbAOGYaBWdChaBc1+L2IDfTgQRewVi8gspDH+7g0eell8/JwHy7JUD8egimlA4umzF/ixsYb51Kw5ho7VlWW0tUWQnMuAcqiD3QFJmBu28Lw/hgAv4Pu3LD4tZcAYOhIzi6itD1l1Kianw4C0T+D11WHo7QS4QAhCmMfr5Cx8nN+qkfrVcIxwJqtweS5SbE0d+l6N4c9vCY+EFrN1w9LpmnZVf7kDwzBQOhRRG2y0CT4/DwJZvdgNKUjiPgiXxuUIVWx5Z30F5aMD6zXIi1xHSSxib3MVMLnUwP6JgWCo9eVgrODW5Du/csXzoDyZiLeXjg53qcn9nue/h+Xs/cUfJAAAAABJRU5ErkJggg==');
}

ul.inline-task-list > li.checked.task-active {
    /* data URI for images/icons/inline-tasks/checked-pressed.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB1UlEQVQ4EWNgGEAQqqOjc4YR5gBRMXGl4tLSM////BaEiWGjv33//r6nu2f17z+/Q4UEBc/CDejo7nnHwc4uKK+mx8DNz8/AwsKCov/Pnz8Mr54+YdiwchHDkcOHGH79+rXn3bt3rkwwVSCblbQMGfiFhTE0g9R8//aNYdOGNQz79+1jkJaWZgBpBonDDQBxBIVFGFiBNqPjj+/eM2zftI7h7MljDAoK8gw+Pj4g5WCAYgBMo62hBkNfWyPDpw/vGd6+esmwffNahnnTJzEI8PEyTF2wkoGDgwOmnwHuUUZGRqDNzGCJqLgEhptXLzOsX7WM4f///wynjh9l0NXVZWjvnwZWA1ILAyguYGFmZgDhjJxCBhExCYbzp44xbN24joEZqKp78iwGcQlJsDxMM4hGMYCNlZkBhEVFRRga27oYhEVEGaSlJBm6J0xlkJSUAMuB5JEB3Atgwf//gDHACmYKA2OjtqmN4QMwHBQUleB6/vz5DWeDGCgGfHz3hkFCShauQFZWjgGEkcGLp4+RuQgvsLNzvj9x7AjD29cvgP4EBijQqej4xbPHDGeB4QJSCzMFHpwSEpJK5eUVZ378+IY3KXNwcL3v7OwwefHi+T2YIQNLAwA3QI2huMdHdgAAAABJRU5ErkJggg==');
}

ul.inline-task-list > li.checked.disabled {
    /* data URI for images/icons/inline-tasks/checked-disabled.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABhUlEQVQ4EcWSv4rCQBDGv9NTIhrFVEFEIc9wnUUar7GwEKwsfISAzT2DD5I0eQGLqyQRiwQLC0HhLLQTTwQRLdTL7LFLVg6Lu+IGlt3MzvebP1ngj/b0W71hGO1Go/EmAJVKxXAcJ0ilUsVH0P1+v+v3+261Wm0fj8dQADzP+8xms0Vd16GqKpLJpMS5XC7YbrcYDoeYTqdYLpfvruu+PvMoylwqlZDP57lL2s/nM0ajESaTCTRNA4kpQADoI6oAlOneorIRhiFmsxkTm6YpQhLiFB1utxtbvV6PMoCEVPZ4PMZgMEA6nYZlWWznOqmC6/XK/LVaDavVCr7vM+BisQC11+12wWM4QKqAO+v1OgqFAubzOYIgYBAS/zQfCUD908pkMmi1WsjlcgzU6XTEfO5nJLVAl4nEN1NRFDSbTUT/mkFoPmT3LUiAw+HAsvJW6D3QiosIGDfRwul02tHEKSAuiAfT3WazAcVyv3iJ5XLZsG07iEp/+JRJHM3kZb1ef3DI/+5f5sKysL0yhGkAAAAASUVORK5CYII=');
}

.content-preview ul.inline-task-list > li {
    /* data URI for images/icons/inline-tasks/unchecked-disabled.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA5klEQVQ4EcVSMQ6DMAx0UUBIICS6A0rf0JGX8EBewtg3ZICBrUhIMABCjSMlxW2UDh3wYuw7O2dxAGfHRQvI8/xW1/XD9/1U92x5Xdehqqp727YCcbOgaZpnkiRpURQg89fstm0wzzMIIWCapqEsyyuSPM3Elznn1mHkMMYUJpXCUaVZgKQwDDE5I45jgjNSyQKlusLzyJvvE1xDLowo2PfdxbVify8gB/26HyV8cogC+X8hiiKrVN0cx1F/qmwUoMP6vkeTEMKxwGHkIFf3jROzLFNWDoLAaeVlWZSVu65TVtaLzssvLOpSQnS3q6kAAAAASUVORK5CYII=');
}

.content-preview ul.inline-task-list > li.checked {
    /* data URI for images/icons/inline-tasks/checked-disabled.png */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABhUlEQVQ4EcWSv4rCQBDGv9NTIhrFVEFEIc9wnUUar7GwEKwsfISAzT2DD5I0eQGLqyQRiwQLC0HhLLQTTwQRLdTL7LFLVg6Lu+IGlt3MzvebP1ngj/b0W71hGO1Go/EmAJVKxXAcJ0ilUsVH0P1+v+v3+261Wm0fj8dQADzP+8xms0Vd16GqKpLJpMS5XC7YbrcYDoeYTqdYLpfvruu+PvMoylwqlZDP57lL2s/nM0ajESaTCTRNA4kpQADoI6oAlOneorIRhiFmsxkTm6YpQhLiFB1utxtbvV6PMoCEVPZ4PMZgMEA6nYZlWWznOqmC6/XK/LVaDavVCr7vM+BisQC11+12wWM4QKqAO+v1OgqFAubzOYIgYBAS/zQfCUD908pkMmi1WsjlcgzU6XTEfO5nJLVAl4nEN1NRFDSbTUT/mkFoPmT3LUiAw+HAsvJW6D3QiosIGDfRwul02tHEKSAuiAfT3WazAcVyv3iJ5XLZsG07iEp/+JRJHM3kZb1ef3DI/+5f5sKysL0yhGkAAAAASUVORK5CYII=');
}
/* module-key = 'com.atlassian.confluence.plugins.quickedit:quick-comment-initial', location = 'css/quick-comment-placeholder.css' */
@charset "UTF-8";
#comments-section .quick-comment-container.add{border-top:0;margin-top:10px;}
.quick-comment-prompt,.quick-comment-loading-container{color:#999999;margin-left:42px;padding-top:5px;}
.quick-comment-error-box{margin-left:18px;width:100%;}
.quick-comment-prompt{border:1px #cccccc solid;border-radius:3px;height:42px;box-shadow:inset -1px 1px 5px rgba(0, 0, 0, 0.1);}
.quick-comment-prompt:active{border:1px #3b73af solid;color:#3b73af;}
.quick-comment-prompt span{padding:6px;}
.quick-comment-loading-container{background-color:#e9e9e9;border:1px solid #cccccc;padding-left:5px;display:none;height:211px;width:100%;}
.quick-comment-loading-message{background-image:url(/confluence/s/en_GB-1988229788/4390/NOCACHE1/5.2/_/download/resources/com.atlassian.confluence.plugins.quickedit:quick-comment-initial/../../../images/icons/wait.gif);background-position:.5em 50%;background-repeat:no-repeat;color:#999999;display:none;padding-left:28px;padding-top:5px;margin:0px;}
.quick-comment-vertical-spacer{display:none;float:left;height:262px;left:-69px;position:relative;width:1px;}
form.aui.quick-comment-form{margin-top:0;}

